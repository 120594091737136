import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import { loaderReducer } from './loaderReducer';
import { generarGuiaReducer } from '../../../pages/Cliente/GenerarGuia/state';
import { generarGuiaReducerPele } from '../../../pages/Cliente/Pele/state';
import { detalleClienteReducer } from '../../../pages/Admin/DetalleCliente/state';
import { validarFacturaReducer } from '../../../pages/Cliente/ValidacionFactura/state';
import { pedidoLegajoReducer } from '../../../pages/Cliente/PedidoLegajo/state';
import { reducer as reduxModalReducer } from 'redux-modal';
import { progressReducer } from './progressReducer';
import { menuReducer } from './menuReducer';
import { procesarLegajosCarpeta } from '../../../pages/Operador/Digitalizar/state';

const reducers = combineReducers({
	auth: authReducer,
	user: userReducer,
	loader: loaderReducer,
	modal: reduxModalReducer,
	generarGuia: generarGuiaReducer,
	generarGuiaPele:generarGuiaReducerPele,
	detalleCliente: detalleClienteReducer,
	validarFactura: validarFacturaReducer,
	pedidoLegajo: pedidoLegajoReducer,
	progress: progressReducer,
	menu: menuReducer,
	procesarCarpeta:procesarLegajosCarpeta
});

export default reducers;

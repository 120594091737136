export const generarGuiaTypes = {
	guiaInicializar: 'guiaInicializar',
	guiaToggle: 'guiaToggle',
	guiaModificar: 'guiaModificar',
	devoPeleToggle:'devoPeleToggle'
};

// ACTIONS

export const guiaInicializar = () => {
	return (dispatch) => {
		dispatch({ type: generarGuiaTypes.guiaInicializar });
	};
}

export const devoPeleToggle = (carpeta) => {
	return (dispatch) => {
		dispatch({ type: generarGuiaTypes.devoPeleToggle, carpeta });
	};
}
export const guiaToggle = (carpeta) => {
	return (dispatch) => {
		dispatch({ type: generarGuiaTypes.guiaToggle, carpeta });
	};
}

export const guiaModificar = (carpeta, name, value) => {
	return (dispatch) => {
		dispatch({ type: generarGuiaTypes.guiaModificar, carpeta, name, value });
	};
}

const actions = {
	devoPeleToggle,
	guiaInicializar,
	guiaToggle,
	guiaModificar
}

export default actions;

// REDUCERS

export const generarGuiaReducerPele = (state = [], action) => {
	switch (action.type) {
		case generarGuiaTypes.guiaInicializar:
			return [];
		case generarGuiaTypes.guiaToggle:
			var existeEnArray = state.filter((x) => x.carpetas[0]?.id === action.carpeta.carpetas[0]?.id).length !== 0;
			if(existeEnArray){
				return state.map((guia) => {
					if(guia.carpetas[0]?.id === action.carpeta.carpetas[0]?.id){
						return { ...guia, estaAgregada: !guia.estaAgregada }
					}
					return guia;
				});
			}

			return state.concat({ ...action.carpeta, estaAgregada: true });

		case generarGuiaTypes.devoPeleToggle:
			let existeEnArrayDevo = state.filter((x) => x.idCarpeta === action.carpeta.idCarpeta).length !== 0;
			if(existeEnArrayDevo){
				return state.map((carpeta) => {
					if(carpeta.idCarpeta === action.carpeta.idCarpeta){
						return { ...carpeta, estaAgregada: !carpeta.estaAgregada }
					}
					return carpeta;
				});
			}

			return state.concat({ ...action.carpeta, estaAgregada: true });
		case generarGuiaTypes.guiaModificar:
			// eslint-disable-next-line no-redeclare
			var existeEnArray = state.filter((x) => x.idCarpeta === action.carpeta.idCarpeta).length !== 0;
			if(existeEnArray){
				return state.map((guia) => {
					if(guia.idCarpeta === action.carpeta.idCarpeta){
						return { ...guia, [action.name]: action.value }
					}
					return guia;
				});
			}

			return state.concat({
				...action.carpeta,
				estaAgregada: false,
				[action.name]: action.value
			});
		default:
			return state;
	}
};
